import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6" }
const _hoisted_3 = { class: "space-x-3" }
const _hoisted_4 = { class: "flex items-center mb-6 justify-between" }
const _hoisted_5 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_6 = ["data-dusk"]
const _hoisted_7 = {
  class: "flex flex-col max-w-2xl p-8",
  style: {"width":"100vh"}
}
const _hoisted_8 = { class: "flex flex-row justify-between items-center mb-8" }
const _hoisted_9 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_10 = { class: "flex flex-row justify-end space-x-3 mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardHeader = _resolveComponent("CardHeader")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_PhoneNumberInput = _resolveComponent("PhoneNumberInput")!
  const _component_AvatarUpload = _resolveComponent("AvatarUpload")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_RolesSelector = _resolveComponent("RolesSelector")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseCard, { class: "space-y-8" }, {
      default: _withCtx(() => [
        _createVNode(_component_CardHeader, {
          title: _ctx.$t('platform.user.new'),
          onBack: _ctx.back
        }, null, 8, ["title", "onBack"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.givenName,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.givenName) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate('given_name')))
            ],
            label: _ctx.$t('platform.patient.first-name'),
            error: _ctx.errors.given_name ? _ctx.errors.given_name[0] : null,
            "data-dusk": "new-user-given-name"
          }, null, 8, ["modelValue", "label", "error"]),
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.familyName,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.familyName) = $event)),
              _cache[3] || (_cache[3] = ($event: any) => (_ctx.validate('family_name')))
            ],
            label: _ctx.$t('platform.profile.family_name'),
            error: _ctx.errors.family_name ? _ctx.errors.family_name[0] : null,
            "data-dusk": "new-user-family-name"
          }, null, 8, ["modelValue", "label", "error"]),
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.email,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
              _cache[5] || (_cache[5] = ($event: any) => (_ctx.validate('email')))
            ],
            label: _ctx.$t('platform.patient.email-address'),
            type: "email",
            error: _ctx.errors.email ? _ctx.errors.email[0] : null,
            "data-dusk": "new-user-email"
          }, null, 8, ["modelValue", "label", "error"]),
          _createVNode(_component_PhoneNumberInput, {
            ref: "phoneInput",
            modelValue: _ctx.phone,
            "onUpdate:modelValue": [
              _cache[6] || (_cache[6] = ($event: any) => ((_ctx.phone) = $event)),
              _cache[7] || (_cache[7] = ($event: any) => (_ctx.validate('phone')))
            ],
            label: _ctx.$t('platform.login.phone-number'),
            class: "w-full",
            error: _ctx.errors.phone ? _ctx.errors.phone[0] : null,
            "is-optional": ""
          }, null, 8, ["modelValue", "label", "error"]),
          (_ctx.gmcCodeType)
            ? (_openBlock(), _createBlock(_component_BaseTextInput, {
                key: 0,
                modelValue: _ctx.gmcCode,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.gmcCode) = $event)),
                label: _ctx.gmcCodeType.label,
                "is-optional": "",
                error: _ctx.errors.gmcCode ? _ctx.errors.gmcCode[0] : null
              }, null, 8, ["modelValue", "label", "error"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_AvatarUpload, {
          id: _ctx.avatarId,
          "onUpdate:id": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.avatarId) = $event)),
          "is-optional": "",
          onLoading: _ctx.handleAvatarLoading
        }, null, 8, ["id", "onLoading"]),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
            type: "submit",
            loading: _ctx.avatarLoading || _ctx.saving,
            "data-dusk": "create-user-button",
            onClick: _ctx.createUser
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.user.create')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])), [
            [_directive_allow, 'user:create']
          ]),
          _createVNode(_component_ButtonLink, {
            color: "ghost",
            to: _ctx.usersPath,
            disabled: _ctx.avatarLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
            ]),
            _: 1
          }, 8, ["to", "disabled"])
        ])
      ]),
      _: 1
    }),
    (_ctx.organisationType === 'organisations' && _ctx.roles.length)
      ? (_openBlock(), _createBlock(_component_BaseCard, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('platform.role.roles')), 1),
              _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                color: "ghost",
                class: "ml-4",
                "left-icon": "add",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setRoleAssignModalVisibility(true)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.role.assign', 1)), 1)
                ]),
                _: 1
              })), [
                [_directive_allow, 'user-role:assign']
              ])
            ]),
            _createVNode(_component_DataTable, {
              loading: _ctx.loading,
              columns: [
          { name: 'name', label: '' },
          { name: 'actions', label: '', align: 'right' }
        ],
              rows: _ctx.roleAssignments,
              class: "w-full",
              "show-table-header": false,
              "no-data-message": _ctx.$t('platform.role.no-roles-assigned')
            }, {
              "cell.name": _withCtx(({ row }) => [
                _createElementVNode("div", {
                  class: "font-medium leading-tight text-gray-900",
                  "data-dusk": `table-cell-role-${row.id}-name`
                }, _toDisplayString(row.name), 9, _hoisted_6)
              ]),
              "cell.actions": _withCtx(({ row }) => [
                _withDirectives((_openBlock(), _createBlock(_component_BasePopover, { position: "left" }, {
                  button: _withCtx(() => [
                    _createVNode(_component_SpecialityButton, {
                      "data-dusk": `role-${row.id}-popover`
                    }, null, 8, ["data-dusk"])
                  ]),
                  menu: _withCtx(() => [
                    _createVNode(_component_PopoverButton, {
                      "data-dusk": `role-${row.id}-unassign`,
                      onClick: ($event: any) => (_ctx.deleteRoleAssignment(row.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.role.unassign')), 1)
                      ]),
                      _: 2
                    }, 1032, ["data-dusk", "onClick"])
                  ]),
                  _: 2
                }, 1024)), [
                  [_directive_allow, 'user-role:revoke']
                ])
              ]),
              _: 1
            }, 8, ["loading", "rows", "no-data-message"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showRoleModal)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 1,
          onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setRoleAssignModalVisibility(false)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t('platform.role.assign', 2)), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "focus:outline-none hover:bg-gray-300 bg-gray-200 h-6 w-6 flex items-center justify-center rounded-md",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setRoleAssignModalVisibility(false)))
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: "close",
                    class: "text-gray-900 stroke-1.5",
                    height: "8px"
                  })
                ])
              ]),
              _createVNode(_component_RolesSelector, {
                modelValue: _ctx.selectedRoles,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedRoles) = $event)),
                roles: _ctx.roles
              }, null, 8, ["modelValue", "roles"]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BaseButton, {
                  color: "ghost",
                  class: "ml-4",
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setRoleAssignModalVisibility(false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseButton, { onClick: _ctx.createRoleAssignments }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}